import { combineReducers } from "redux";
import auth from "./auth";
import language from "./language";
import common from "./common";
import alert from "./alert";
import announcement from './Announcement/announcement';
import menuSetting from './ConfigManagement/menusetting';
import fullReport from './ReportManagement/fullReport';
//persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import downline from "./AccountManagement/Downline/downline";
import result from "./Result/result";
import onlineList from "./AccountManagement/OnlineList/onlineList";
import bettingHistory from './CompanyReport/BettingHistory';
import fightingHistory from './AccountManagement/FightingHistory/FightingHistory';

import loginHistory from "./ViewLog/loginHistory";
import transactionHistory from "./ViewLog/transactionHistory";
import drawDateHistory from "./ViewLog/drawDateHistory";
import systemHistory from './ViewLog/systemHistory';
const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "language"
    ],
};

const appReducer = combineReducers({
    auth,
    language,
    common,
    alert,
    downline,
    result,
    transactionHistory,
    drawDateHistory,
    onlineList,
    announcement,
    bettingHistory,
    fightingHistory,
    menuSetting,
    loginHistory,
    systemHistory,
    fullReport
});

const rootReducer = (state, action) => {
    if (action.type === "LOGIN_AS_SUCCESS" || action.type === "LOGOUT") {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
