import {GET_LIST_REPORT_BY_ID, GET_LIST_REPORT_TOTO_BY_ID} from "../../actions/type";

const initialState = {
    fullReportList: {},
    fullReportTotoList: {}
};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_LIST_REPORT_BY_ID:
            return {
                ...state,
                fullReportList: payload
            }
        case GET_LIST_REPORT_TOTO_BY_ID:
            return {
                ...state,
                fullReportTotoList: payload
            }
        default:
            return state;
    }
}