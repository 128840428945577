import "i18n";
import './App.css';
import React, {Suspense, useEffect} from "react";
import {persistor, store} from "./store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {keepAlive} from "./actions/auth";
import AppRouter from "./AppRouter";

const App = () => {

    useEffect(() => {
        const keepAliveInterval = setInterval(() => {
            store.getState().auth.token && store.dispatch(keepAlive());
        }, 5000);
        return () => clearInterval(keepAliveInterval);
    }, []);

    console.log('========', window.location)
    return (
        <Provider store={store}>
            <Suspense fallback={<div>Loading...</div>}>
                <PersistGate persistor={persistor}>
                    <AppRouter/>
                </PersistGate>
            </Suspense>
        </Provider>)
}

export default App;
