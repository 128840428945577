import React, {useEffect, useState} from 'react';
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//REDUX
import {changeLanguage} from 'actions/language';
import {connect} from 'react-redux';
//Utils
import { useTranslation } from "react-i18next";
import "../../../assets/color.css";
const useStyles = makeStyles({
    translation:{
        color:'var(--color3)',
        cursor:'pointer'
    },
    currentLanguage: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em',
        border: '1px slolid var(--color23)'
    },
})


const Language = ({changeLanguage,globalLanguage}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [language, setLanguage] = useState(globalLanguage);
    const [renderLang, setRenderLang] = useState("en");
    const { i18n } = useTranslation();
    const classes = useStyles();

    const langObj = {
        "en": "EN" ,
        "cn": "CN"
    }

    useEffect(() => {
        setLanguage(i18n.language?.toLowerCase().slice(0,2));
        setRenderLang(i18n.language?.slice(0,2))
    }, [i18n.language]);

    const handleLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setAnchorEl(null);
        setLanguage(lng.toUpperCase());
        changeLanguage(lng.toUpperCase());
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Badge badgeContent={0} color="secondary"  onClick={handleClick} className={classes.translation}>
                <Typography variant='body1' className={classes.currentLanguage}>{langObj[renderLang]}</Typography>
                <KeyboardArrowDownIcon style={{color:'var(--color3)'}}/>
            </Badge>

            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem selected={language === 'English'} onClick={() => handleLanguage("en")}>English</MenuItem>
                <MenuItem selected={language === '繁體中文'} onClick={() => handleLanguage("cn")}>繁體中文</MenuItem>
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalLanguage:state.language.language
})

export default connect(mapStateToProps,{changeLanguage})(Language);