export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const VERIFY_CODE_FAIL="VERIFY_CODE_FAIL";
export const LOGIN_PASSED="LOGIN_PASSED";
export const LOGIN_FAIL="LOGIN_FAIL";
export const USER_LOADED="USER_LOADED";
export const RESET_FAIL_MSG="RESET_FAIL_MSG";
export const AUTH_ERROR="AUTH_ERROR";
export const LOGOUT="LOGOUT";
export const CHANGE_LOGOUT_STATUS="CHANGE_LOGOUT_STATUS";
export const SET_FIRST_LOGIN="SET_FIRST_LOGIN";
export const FLAG_FORGOT_PASSWORD_SUCCESS="FLAG_FORGOT_PASSWORD_SUCCESS";
export const FLAG_RESET_PASSWORD_SUCCESS="FLAG_RESET_PASSWORD_SUCCESS";
export const VERIFY_TOKEN_RESET_PASSWORD_SUCCESS="VERIFY_TOKEN_RESET_PASSWORD_SUCCESS";
export const FLAG_VERIFY="FLAG_VERIFY";
export const FLAG_MAIL_EXPIRED="FLAG_MAIL_EXPIRED";
export const CHANGE_LANGUAGE="CHANGE_LANGUAGE";
export const TOKEN_ERROR="TOKEN_ERROR";
export const TOKEN_EXPIRED="TOKEN_EXPIRED";
export const CHANGE_PASSWORD="CHANGE_PASSWORD";

export const INTERNAL_ERROR="INTERNAL_ERROR";
export const TOGGLE_LEFT_SIDE_BAR="TOGGLE_LEFT_SIDE_BAR";
export const SELECTED_LIST_ITEM="SELECTED_LIST_ITEM";

// RESULT
export const GET_ALL_LIST_RESULT="GET_ALL_LIST_RESULT";
export const GET_ALL_LIST_RESULT_TOTO="GET_ALL_LIST_RESULT_TOTO";

export const CREATE_NEW_RESULT_ALL_NUMBER="CREATE_NEW_RESULT_ALL_NUMBER";
export const CREATE_NEW_RESULT_TOTO="CREATE_NEW_RESULT_TOTO";

export const GET_RESULT_BY_DATE="GET_RESULT_BY_DATE";
export const GET_RESULT_BY_NUMBER="GET_RESULT_BY_NUMBER";
export const GET_REVIEW_RESULT="GET_REVIEW_RESULT";
export const GET_REVIEW_RESULT_TOTO="GET_REVIEW_RESULT_TOTO";
export const CONFIRM_RESULT="CONFIRM_RESULT";
export const GET_GUIDE_PAYOUT="GET_GUIDE_PAYOUT";
// ANNOUNCEMENT
export const GET_LIST_ANNOUCEMENT="GET_LIST_ANNOUCEMENT";
export const GET_LIST_NEWS = "GET_LIST_NEWS";
export const READ_ANNOUNCEMENT="READ_ANNOUNCEMENT";

export const ADD_NEW_ANNOUNCEMENT="ADD_NEW_ANNOUNCEMENT";

export const EDIT_ANNOUNCEMENT="EDIT_ANNOUNCEMENT";
// ACCOUNT
export const UPDATE_PHONE_PROFILE="UPDATE_PHONE_PROFILE";
export const UPDATE_NICKNAME_PROFILE="UPDATE_NICKNAME_PROFILE";
export const GET_LIST_SUB_ACCOUNT="GET_LIST_SUB_ACCOUNT";
export const GET_LIST_ACCOUNT_OPTION="GET_LIST_ACCOUNT_OPTION";
export const CREATE_SUB_ACCOUNT="CREATE_SUB_ACCOUNT";
export const EDIT_SUB_ACCOUNT="EDIT_SUB_ACCOUNT";
export const CHECK_USERID_IS_AVAILABLE="CHECK_USERID_IS_AVAILABLE";
export const RESET_CHECK_STATUS="RESET_CHECK_STATUS";
export const GET_SUB_ACCOUNT_DETAIL_BY_ID="GET_SUB_ACCOUNT_DETAIL_BY_ID";
// DOWNLINE
export const GET_LIST_DOWNLINE="GET_LIST_DOWNLINE";
export const GET_LIST_AGENT="GET_LIST_AGENT";
export const GET_LIST_COMPANY="GET_LIST_COMPANY";
export const GET_AGENT_DETAIL="GET_AGENT_DETAIL";

export const GET_COMPANY_DETAIL="GET_COMPANY_DETAIL";
export const GET_MASTER_AGENT_DETAIL="GET_MASTER_AGENT_DETAIL";
export const GENERATE_USER_ID="GENERATE_USER_ID";
export const GET_GAME_SETTING_BY_AGENT_ID="GET_GAME_SETTING_BY_AGENT_ID";
export const GET_LIST_DOWNLINE_OPTION="GET_LIST_DOWNLINE_OPTION";
export const WITHDRAW_MONEY="WITHDRAW_MONEY"
export const DEPOSIT_MONEY="DEPOSIT_MONEY"

export const ADD_AGENT_DOWNLINE="ADD_AGENT_DOWNLINE";
export const UPDATE_AGENT_DOWNLINE="UPDATE_AGENT_DOWNLINE";
export const UPDATE_AGENT_DOWNLINE_SETTING="UPDATE_AGENT_DOWNLINE_SETTING";
export const UPDATE_PHONE_AGENT_DOWNLINE="UPDATE_PHONE_AGENT_DOWNLINE";
export const UPDATE_AGENT_COPY_SETTING_FROM="UPDATE_AGENT_COPY_SETTING_FROM";
export const UPDATE_AGENT_COPY_SETTING_TO="UPDATE_AGENT_COPY_SETTING_TO";
export const UPDATE_AGENT_DOWNLINE_CREDIT="UPDATE_AGENT_DOWNLINE_CREDIT";
export const UPDATE_AGENT_DOWNLINE_BALANCE="UPDATE_AGENT_DOWNLINE_BALANCE";
export const UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE="UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE";

export const ADD_MEMBER_DOWNLINE="ADD_MEMBER_DOWNLINE";
export const UPDATE_MEMBER_DOWNLINE="UPDATE_MEMBER_DOWNLINE";
export const UPDATE_COMPANY_DOWNLINE="UPDATE_COMPANY_DOWNLINE";

export const UPDATE_PHONE_MEMBER_DOWNLINE="UPDATE_PHONE_MEMBER_DOWNLINE";
export const UPDATE_MEMBER_DOWNLINE_CREDIT="UPDATE_MEMBER_DOWNLINE_CREDIT";
export const UPDATE_MEMBER_DOWNLINE_BALANCE="UPDATE_MEMBER_DOWNLINE_BALANCE";

// Alert
export const EDIT_ALERT="EDIT_ALERT";
export const CREATE_ALERT="CREATE_ALERT";

export const ERROR_ALERT="ERROR_ALERT";
export const DELETE_ALERT="DELETE_ALERT";
export const CLOSE_ALERT="CLOSE_ALERT";

// BETTING
export const GET_DRAW_DATE="GET_DRAW_DATE";
export const GET_CREDIT_INFO="GET_CREDIT_INFO";
export const CREATE_SIMPLE_BET="CREATE_SIMPLE_BET"
export const CREATE_MASS_BET="CREATE_MASS_BET"
export const CREATE_MASS_BET_TWO="CREATE_MASS_BET_TWO"
export const BETTING_HISTORY="BETTING_HISTORY"
export const BETTING_HISTORY_TOTO="BETTING_HISTORY_TOTO"
export const CANCEL_BETTING="CANCEL_BETTING"

// ONLINE LIST

export const GET_LIST_ONLINE_LIST = "GET_LIST_ONLINE_LIST";
export const LOGOUT_BY_USER_ID = "LOGOUT_BY_USER_ID";
export const GET_LIST_FIGHTING_HISTORY = "GET_LIST_FIGHTING_HISTORY";
export const GET_LIST_USER_INACTIVE_TICKET_FIGHTING = "GET_LIST_USER_INACTIVE_TICKET_FIGHTING";

export const CANCEL_FIGHTING="CANCEL_FIGHTING"

export const GET_LEFT_MENU="GET_LEFT_MENU"

export const GET_MENU_CONFIG="GET_MENU_CONFIG"
export const UPDATE_MENU_SETTING="UPDATE_MENU_SETTING"
export const GET_COMMISSION_BY_ID="GET_COMMISSION_BY_ID";
export const UPDATE_COMMISSION="UPDATE_COMMISSION"
export const LOGIN_HISTORY_LOG = "LOGIN_HISTORY_LOG";
export const TRANSACTION_HISTORY_LOG = "TRANSACTION_HISTORY_LOG";
export const SYSTEM_HISTORY_LOG = "SYSTEM_HISTORY_LOG";

export const DRAWDAY_LOG = "DRAWDAY_LOG ";

export const GET_DRAW_DAYS = "GET_DRAW_DAYS ";
export const UPDATE_DRAW_DAYS="UPDATE_DRAW_DAYS";


export const GET_LIST_REPORT_BY_ID="GET_LIST_REPORT_BY_ID";
export const GET_LIST_REPORT_TOTO_BY_ID="GET_LIST_REPORT_TOTO_BY_ID";
export const GET_LIST_DRAW_DATE="GET_LIST_DRAW_DATE";
export const CREATE_NEW_FIGHTING="CREATE_NEW_FIGHTING";

export const GET_LIST_MA="GET_LIST_MA";


