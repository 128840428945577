import {GET_MENU_CONFIG} from "../../actions/type";

const initialState = {
    menu: [],
    allMenu: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_MENU_CONFIG:
           return {
               ...state,
               menu: payload
           }
        default:
            return state;
    }
}
