import {
    CANCEL_FIGHTING, CREATE_NEW_FIGHTING,
    GET_LIST_FIGHTING_HISTORY, GET_LIST_USER_INACTIVE_TICKET_FIGHTING
} from "../../../actions/type";

const initialState = {
    listFighting: [],
    isUpdated: false,
    listUserInactiveFighting: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_FIGHTING_HISTORY:
            return {
                ...state,
                listFighting: payload
            }
        case GET_LIST_USER_INACTIVE_TICKET_FIGHTING:
            return {
                ...state,
                listUserInactiveFighting: payload
            }
        case CANCEL_FIGHTING:
        case CREATE_NEW_FIGHTING:
            return {
                ...state,
                isUpdated: !state?.isUpdated
            }
        default:
            return state;
    }
}
