import {connect} from "react-redux";
import {Box, Tabs, Tab} from "@mui/material";
import {selectItem} from "../../../actions/common";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GetDetectBrowserInfo} from "../../../constants/constants";
import Grid from "@mui/material/Grid";
import "../../../assets/color.css";
import TitlePage from "../../../components/page/TitlePage";
import {useHistory, useLocation} from "react-router-dom";
import {handleUrl} from "../../../utils/setUrl";
import qs from "qs";
import FullReport4D from "./FullReport4D";
import FullReportTOTO from "./FullReportTOTO";
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const FullReports = ({selectItem}) => {
    const {t} = useTranslation();
    const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
    const [tab, setTab] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const locationValue = handleUrl(
        qs.parse(location.search, { ignoreQueryPrefix: true}) || {}
    )
    useEffect(() => {
        locationValue?.tab === 'toto' && setTab(1);
    },[locationValue]);
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        history.push({
            search: `?tab=${newValue === 0 ? '4d' : 'toto'}`
        })
    }
    return (
        <Fragment>
            <Box px={2}>
                <Grid item xs={12}>
                    <TitlePage title={t('Full Report')}/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', textTransform: 'none'}}>
                        <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example" TabIndicatorProps={{style: {background: 'var(--color2)', height: '4px'}}}>
                            <Tab label={t("4D")} {...a11yProps(0)} sx={{textTransform: 'none', fontSize: '16px', fontWeight: '400', letterSpacing: '0.05em', width: !(isDesktop || isLaptop || isTablet) ? '110px': '250px'}}/>
                            <Tab label={t("Toto")} {...a11yProps(1)} sx={{textTransform: 'none', fontSize: '16px', fontWeight: '400', letterSpacing: '0.05em', width: !(isDesktop || isLaptop || isTablet) ? '110px': '250px'}}/>
                            {/*<Tab label={t("Payout Guide")} {...a11yProps(2)} sx={{textTransform: 'none', fontSize: '16px', fontWeight: '400', letterSpacing: '0.05em', width: !(isDesktop || isLaptop || isTablet) ? '110px': '250px'}}/>*/}
                        </Tabs>
                    </Box>
                </Grid>
            </Box>
            <Box>
                <TabPanel value={tab} index={0}>
                    <FullReport4D/>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <FullReportTOTO/>
                </TabPanel>
                {/*<TabPanel value={tab} index={2}>*/}
                {/*    <GuidePayout/>*/}
                {/*</TabPanel>*/}
            </Box>
        </Fragment>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {selectItem})(FullReports);