
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled, ThemeProvider } from "@mui/material/styles";
import { GetDetectBrowserInfo } from "constants/constants";
import { mdTheme } from './Theme';
import User from "../../GlobalManagement/navBar/User";
import { connect } from "react-redux";
import {CssBaseline} from "@mui/material";
import {selectItem, toggleLeftSideBar} from "../../../actions/common";
import MuiDrawer from "@mui/material/Drawer"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MainListItems from "./ListItems";
import React, {useEffect} from "react";
import AlertSuccess from "../../../components/alert/AlertSuccess";
import AlertFail from "../../../components/alert/AlertFail";
import {makeStyles} from "@mui/styles";
import {getListNews} from "../../../actions/announcement/announcement";
import "../../../assets/color.css";
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "var(--color119)",
    height: '60px',
    boxShadow: 'unset'
}));
const useStyles = makeStyles({
    bar: {
        // position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '10px 0',
        backgroundColor: 'var(--color73)',
        color: 'white',
        fontFamily: 'Gelasio',
        fontSize: '18px'
    },
    bar_content: {
        color: 'var(--color16)',
        fontWeight: '700',
        display: 'block', /* Important to give the content a width */
        width: '100%',
        maxHeight: '25px',
        transform: 'translateX(100%)', /* Animation start out of the screen */
        /* Add the animation */
        animation: '$move 30s linear infinite',
        whiteSpace: 'nowrap',
        "&:hover": {
            animationPlayState: "paused"
        }
    },

    /* Create the animation */
    '@keyframes move': {
        'to': { transform: 'translateX(-100%) '}
    }

});
const Layout = (Component, pageLabel) => {
    const ComposedComponent = ({
                                   globalLanguage,
                                   toggleLeftSideBar, sideBarStatus, status, errorStatus,
                                   notifyContent, selectItem,
                                   getListNews
    }) => {
        const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
        const classes = useStyles();

        useEffect(() => {
             getListNews();
        }, [])

        useEffect(() => {
            pageLabel && selectItem(pageLabel);
        }, [pageLabel, selectItem]);

        const toggleDrawer = () => {
            toggleLeftSideBar(!sideBarStatus);
        };
        return (
            <ThemeProvider theme={mdTheme(globalLanguage)}>
                <Box sx={{display: "flex", overflow: 'hidden'}}>
                    <CssBaseline/>

                    {/* Header */}
                    <AppBar position="absolute">
                        <Grid container alignItems="center" height={'100%'}>
                            <Grid item xs={12} sx={{backgroundColor: "var(--color119)"}}>
                                <Grid container justifyContent="flex-end" alignItems='center'>
                                    <Grid item xs={12}>
                                        <Box>
                                            <User/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AppBar>
                    {/* Left Drawer */}

                    {(isDesktop || isLaptop) ? (
                        <Drawer variant="permanent" open={sideBarStatus}>
                            {/* 縮放Button */}
                            {sideBarStatus ? <Box
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    zIndex: -1,
                                    position: "absolute",
                                    top: 70,
                                    right: -25,
                                    backgroundColor: 'var(--color3)',
                                    borderRadius: 10,
                                    width: 40,
                                    height: 60,
                                    cursor: 'pointer'
                                }}

                            >

                                <Box sx={{
                                    width: 18,
                                    height: 18,
                                    marginTop: "20px"
                                }} ml={2}>
                                    <ArrowBackIosIcon style={{color: 'var(--color74)'}} fontSize='small'/>
                                </Box>


                            </Box> : <Box
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    zIndex: 100,
                                    position: "fixed",
                                    cursor: 'pointer',
                                    top: 125,
                                    left: 15,
                                    width: "40px",
                                    height: "60px",
                                    backgroundColor: "var(--color3)",
                                    borderRadius: "40px"
                                }}
                            >
                                <ArrowForwardIosIcon style={{color: 'var(--color74)', marginTop: "20px", marginLeft: "16px"}} fontSize='small'/>
                            </Box>}
                            <MainListItems toggleDrawer={sideBarStatus}/>
                        </Drawer>

                    ) : null}

                    <Box
                        component="main"
                        sx={{
                            backgroundColor: "var(--color131)",
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                    >
                        {notifyContent &&
                            <Box sx={{
                                // mt: '100px',
                                pt: '60px',
                                pl: sideBarStatus
                                    ? (
                                        '0'
                                    )
                                    : (
                                        isDesktop ? '60px'
                                            : isLaptop ? '0'
                                                : isTablet ? '1.5rem'
                                                    : '1rem'
                                    )

                            }}>
                                <div className={classes.bar}>
                                   <span className={classes.bar_content}>
                                       {notifyContent}
                                   </span>
                                </div>
                            </Box>
                        }


                        <Box>
                            <Box sx={{
                                pt: (!isDesktop || !isLaptop || !isTablet) ? notifyContent ? '1.4rem': '4.4rem' : '150px',
                                pl: sideBarStatus
                                            ? (
                                                isDesktop ? '60px'
                                                    : isLaptop ? '40px'
                                                    : isTablet ? '1.5rem'
                                                        : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                    : isTablet ? '1.5rem'
                                                        : '1rem'
                                            )
                                    ,
                                pr: sideBarStatus
                                            ? (
                                                isDesktop ? '60px'
                                                    : isLaptop ? '40px'
                                                    : isTablet ? '1.5rem'
                                                        : '1rem'
                                            )
                                            : (
                                                isDesktop ? '100px'
                                                    : isLaptop ? '60px'
                                                    : isTablet ? '1.5rem'
                                                        : '1rem'
                                            )
                                    ,
                                pb: isDesktop ? '50px'
                                    : isLaptop ? '50px'
                                        : isTablet ? '1.5rem'
                                            : '1.5rem',
                                minHeight: "89vh"
                            }}>
                                <Component/>
                            </Box>
                            {/*// Alert */}
                            {status === true ? <AlertSuccess/> : null}
                            {errorStatus === true ? <AlertFail/> : null}
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );

    };
    const mapStateToProps = (state) => ({
        globalLanguage: state.language.language,
        sideBarStatus: state.common.lefSideBarControl,
        errorStatus: state.alert.errorStatus,
        status: state.alert.status,
        notifyContent: state.announcement.notifyContent
    });
    return connect(mapStateToProps, {toggleLeftSideBar, selectItem, getListNews})(ComposedComponent);
}

export default Layout;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open"})(({theme, open}) => ({
    backgroundColor: "var(--color131)",
    "& .MuiDrawer-paper": {
        position: "relative",
        top: "4.5rem",
        height: "95vh",
        whiteSpace: "nowrap",
        width: "250px",
        overflowY: 'visible',
        borderRight: 'none',
        boxShadow: "0 3px 5px 2px var(--color75)",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 0,
        }),
        "&::before":{
            content: '""',
            width: "100%" ,
            height: "50px" ,
            position: "absolute" ,
            left: "0" ,
            bottom: "28px" ,
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%)"
        },
        ...(!open && {
            overflow: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: 0,
            }),
            width: '30px',
            [theme.breakpoints.up('sm')]: {
                width: '30px',
            },
        }),
    },
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: '30px',
        [theme.breakpoints.up('sm')]: {
            width: '30px',
        },
    }),
}));