export const handleUrl = (url) => {
    const tryParseInt = (value) => {
        const result = parseInt(value);
        return isNaN(result) ? value : result;
    };

    // eslint-disable-next-line no-unused-vars
    const parseObjectValues = (obj = {}) => {
        Object.keys(obj).forEach((k) => {
            obj[k] = tryParseInt(obj[k]);
        });

        return obj;
    };

    return url;
};