import {
    ADD_NEW_ANNOUNCEMENT,
    CREATE_ALERT, EDIT_ALERT, EDIT_ANNOUNCEMENT,
    ERROR_ALERT,
    GET_LIST_ANNOUCEMENT, GET_LIST_NEWS,
    READ_ANNOUNCEMENT
} from "../type";
import axios from "axios";
import {REACT_API_LOCATION} from "../../constants/constants";
export const getListAnnouncement = () => async (dispatch) =>{
    try {
        // Call API
        const res = await axios.get(`${REACT_API_LOCATION}/backoffice-service/announcement`);
        dispatch({
            type:GET_LIST_ANNOUCEMENT,
            payload: res?.data?.data
        });
    } catch (error) {
        console.log(error)
    }
}

export const getListNews = () => async (dispatch) =>{
    try {
        // Call API
        const res = await axios.get(`${REACT_API_LOCATION}/backoffice-service/announcement/news`);
        let newMsg = ''
        res?.data?.data?.map((item, index) => {
            if(index === res?.data?.data?.length - 1){
                newMsg = newMsg?.concat(item?.content);
            } else {
                newMsg = newMsg?.concat(item?.content).concat(',');
            }
        })
        dispatch({
            type:GET_LIST_NEWS,
            payload: newMsg
        })
    } catch (error) {
        console.log(error)
    }
}
export const readAnnouncement = () => (dispatch) =>{
    try {
        // Call API

        dispatch({
            type: READ_ANNOUNCEMENT
        })
    } catch (error) {
        console.log(error)
    }
}

export const addNewAnnoucement = (data) => async (dispatch) =>{
    try {
        const body = JSON.stringify(data);
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.post(`${REACT_API_LOCATION}/backoffice-service/announcement`,body, config);
        dispatch({
            type: ADD_NEW_ANNOUNCEMENT
        })
        dispatch({
            type: CREATE_ALERT,
            payload: "Create Success",
        })
    } catch (error) {
        dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
    }
}

export const editAnnoucement = (data) => async (dispatch) =>{
    try {
        const body = JSON.stringify(data);
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.put(`${REACT_API_LOCATION}/backoffice-service/announcement`,body, config);
        dispatch({
            type: EDIT_ANNOUNCEMENT
        })
        dispatch({
            type: EDIT_ALERT,
            payload: "Edit Success",
        })
    } catch (error) {
        dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
    }
}