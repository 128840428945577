export const convertDateDisplay = (date) => {
    const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const today = new Date(date);
    const dd   = today.getDate();
    const mm   = monthNames[today.getMonth()];
    const yyyy = today.getFullYear();
    const day  = days[today.getDay()];
    return  `${day}, ${dd} ${mm} ${yyyy}`;

}
export const convertDateSubmit = (date) => {
    const today = new Date(date);
    const dd   = (today.getDate()).toString().padStart(2, "0");
    const mm   = (today.getMonth() + 1).toString().padStart(2, "0");
    const yyyy = today.getFullYear();
    return  `${dd}/${mm}/${yyyy}`;
}
export const getWedDay = (days, hours , dateCopy) => {
    return (days === 'WEDNESDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 3) % 7 || 7),
        ),
    );
}
export const getSatDay = (days, hours , dateCopy) => {
    return (days === 'SATURDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 6) % 7 || 7),
        ),
    );
}
export const getSunDay = (days, hours , dateCopy) => {
    return (days === 'SUNDAY' && hours < 16) ? new Date() : new Date(
        dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 7) % 7 || 7),
        ),
    );
}

export const getAllNextDate = () => {
    const today = new Date();
    const dateCopy = new Date(today.getTime());
    const days = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'];
    const hours = today?.getHours();
    const day  = days[today.getDay()];
    let nextSartuday = getSatDay(day, hours, dateCopy)
    let nextSunday = getSunDay(day, hours, dateCopy);
    let nextWed = getWedDay(day, hours, dateCopy);
    const dateTmp = [nextSartuday, nextWed, nextSunday];
    return {
        date: {
            wedDay: nextWed,
            satDay: nextSartuday,
            sunDay: nextSunday
        },
        listDate: dateTmp?.sort((a, b) => a - b)
    }
}

export const parseDownLineList = (downlineOption, accountSelected) => {
    return downlineOption?.map((item) =>{
            let downlines= null;
            if (item.downlines !== null){
                downlines = item?.downlines.map((item1) => {
                    let downlinesChild= null;
                    if(item1?.downlines?.length > 0) {
                        downlinesChild = item1?.downlines.map((item2) => {
                            let downlinesGrandChild= null;
                            if(item2?.downlines?.length > 0) {
                                item2?.downlines?.map((item3) => {
                                    return {label: `${item3.userId} (${item3?.userName})`, id: item3.userId, isDefaultValue: item3.userId === accountSelected}
                                })
                            }
                            return {label: `${item2.userId} (${item2?.userName})`, id: item2.userId, isDefaultValue: item2.userId === accountSelected, children: downlinesGrandChild}
                        })
                    }
                    return {label: `${item1.userId} (${item1?.userName})`, id: item1.userId, isDefaultValue: item1.userId === accountSelected, children: downlinesChild}
                })
            }
            return { label: `${item.userId} (${item?.userName})`, children: downlines, id: item.userId, isDefaultValue: item.userId === accountSelected}
        }
    )
}
