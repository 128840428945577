import {
    ADD_AGENT_DOWNLINE,
    ADD_MEMBER_DOWNLINE,
    DEPOSIT_MONEY,
    GENERATE_USER_ID,
    GET_AGENT_DETAIL,
    GET_GAME_SETTING_BY_AGENT_ID,
    GET_LIST_AGENT,
    GET_LIST_DOWNLINE,
    GET_LIST_DOWNLINE_OPTION,
    GET_LIST_COMPANY,
    GET_MASTER_AGENT_DETAIL,
    UPDATE_AGENT_COPY_SETTING_FROM,
    UPDATE_AGENT_COPY_SETTING_TO,
    UPDATE_AGENT_DOWNLINE,
    UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE,
    UPDATE_AGENT_DOWNLINE_BALANCE,
    UPDATE_AGENT_DOWNLINE_CREDIT,
    UPDATE_AGENT_DOWNLINE_SETTING,
    UPDATE_MEMBER_DOWNLINE,
    UPDATE_MEMBER_DOWNLINE_BALANCE,
    UPDATE_MEMBER_DOWNLINE_CREDIT,
    UPDATE_PHONE_AGENT_DOWNLINE,
    UPDATE_PHONE_MEMBER_DOWNLINE,
    WITHDRAW_MONEY,
    GET_COMPANY_DETAIL,
    UPDATE_COMPANY_DOWNLINE,
    GET_COMMISSION_BY_ID,
    UPDATE_COMMISSION, GET_LIST_MA
} from "../../../actions/type";

const initialState = {
    downlineList: {
        agentList: [],
        coList: []
    },
    isUpdate: false,
    agentDetail: {},
    memberDetail: {},
    isUpdateSetting: false,
    userIdGenerate: '',
    gameSettingByAgentId: {},
    downlineOption: [],
    companyDetail: {},
    commissionDetail: {},
    listMA: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_DOWNLINE:
            return {
                ...state,
                downlineList: payload
            }
        case GET_LIST_AGENT:
            return {
                ...state,
                downlineList: {
                    ...state.downlineList,
                    agentList: payload
                }
            }
        case GET_LIST_COMPANY:
            return {
                ...state,
                downlineList: {
                    ...state.downlineList,
                    coList: payload
                }
            }
        case GET_AGENT_DETAIL:
            return {
                ...state,
                agentDetail: payload
            }
        case GET_COMPANY_DETAIL:
            return {
                ...state,
                companyDetail: payload
            }
        case GET_GAME_SETTING_BY_AGENT_ID:
            return {
                ...state,
                gameSettingByAgentId: payload
            }
        case GET_MASTER_AGENT_DETAIL:
            return {
                ...state,
                memberDetail: payload
            }
        case ADD_AGENT_DOWNLINE:
        case UPDATE_AGENT_DOWNLINE:
        case UPDATE_AGENT_DOWNLINE_SETTING:
        case UPDATE_PHONE_AGENT_DOWNLINE:
        case UPDATE_AGENT_COPY_SETTING_TO:
        case UPDATE_AGENT_DOWNLINE_CREDIT:
        case UPDATE_AGENT_DOWNLINE_BALANCE:
        case UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE:
        case ADD_MEMBER_DOWNLINE:
        case UPDATE_MEMBER_DOWNLINE:
        case UPDATE_PHONE_MEMBER_DOWNLINE:
        case UPDATE_MEMBER_DOWNLINE_CREDIT:
        case UPDATE_MEMBER_DOWNLINE_BALANCE:
        case WITHDRAW_MONEY:
        case UPDATE_COMPANY_DOWNLINE:
        case DEPOSIT_MONEY:
        case UPDATE_COMMISSION:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        case UPDATE_AGENT_COPY_SETTING_FROM:
            return {
                ...state,
                isUpdate: !state.isUpdate,
                isUpdateSetting: !state.isUpdateSetting,
            }
        case GENERATE_USER_ID:
            return {
                ...state,
                userIdGenerate: payload
            }
        case GET_LIST_DOWNLINE_OPTION:
            return {
                ...state,
                downlineOption: payload
            }
        case GET_COMMISSION_BY_ID:
            return {
                ...state,
                commissionDetail: payload
            }
        case GET_LIST_MA:
            return {
                ...state,
                listMA: payload
            }
        default:
            return state;
    }
}
