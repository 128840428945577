import {
    CONFIRM_RESULT,
    CREATE_NEW_RESULT_ALL_NUMBER, CREATE_NEW_RESULT_TOTO,
    GET_ALL_LIST_RESULT, GET_ALL_LIST_RESULT_TOTO, GET_LIST_DRAW_DATE,
    GET_RESULT_BY_DATE,
    GET_RESULT_BY_NUMBER, GET_REVIEW_RESULT, GET_REVIEW_RESULT_TOTO
} from "../../actions/type";

const initialState = {
    listResultByDate: [
        {
            drawDate: '20/12/2021',
           firstPrizeNumber: '1234',
            secondPrizeNumber: '3456',
            thirdPrizeNumber: '4567',
            starterPrize: ['1234','3443','3455','4444','5555','1234','3443','3455','4444','5555'],
            consoPrize: ['1234','3443','3455','4444','5555','1234','3443','3455','4444','5555'],
        },
        {
            drawDate: '20/12/2022',
           firstPrizeNumber: '1234',
            secondPrizeNumber: '3456',
            thirdPrizeNumber: '4567',
            starterPrize: ['1234','3443','3455','4444','5555','1234','3443','3455','4444','5555'],
            consoPrize: ['1234','3443','3455','4444','5555','1234','3443','3455','4444','5555'],
        },
    ],
    listResultByNumber: [],
    listDrawDate: ['20-12-1996', '20-12-1997'],
    payoutGuide: [{name: '1st Prize', ord1big: '4000.00', ord1small: '3000.00', ibet1big24perm: '166.67', ibet1big12perm: '333.33', ibet1big6perm: '666.67', ibet1big4perm: '1000.00', ibet1small24perm: '125.00', ibet1small12perm: '250.00', ibet1smaill6perm: '500.00', ibet1small4perm: '750.00'},
        {name: '2nd Prize', ord1big: '2000.00', ord1small: '2000.00', ibet1big24perm: '83.33', ibet1big12perm: '166.67', ibet1big6perm: '333.33', ibet1big4perm: '500.00', ibet1small24perm: '83.33', ibet1small12perm: '166.67', ibet1smaill6perm: '333.33', ibet1small4perm: '500.00'},
        {name: '3rd Prize', ord1big: '1000.00', ord1small: '1000.00', ibet1big24perm: '41.67', ibet1big12perm: '83.33', ibet1big6perm: '166.67', ibet1big4perm: '250.00', ibet1small24perm: '41.67', ibet1small12perm: '83.33', ibet1smaill6perm: '166.67', ibet1small4perm: '250.00'},
        {name: 'Starter Prizes', ord1big: '500.00', ord1small: '0.00', ibet1big24perm: '20.83', ibet1big12perm: '41.67', ibet1big6perm: '83.33', ibet1big4perm: '125.00', ibet1small24perm: '0.00', ibet1small12perm: '0.00', ibet1smaill6perm: '0.00', ibet1small4perm: '0.00'},
        {name: 'Consolation Prizes', ord1big: '150.00', ord1small: '0.00', ibet1big24perm: '6.25', ibet1big12perm: '12.50', ibet1big6perm: '25.00', ibet1big4perm: '37.50', ibet1small24perm: '0.00', ibet1small12perm: '0.00', ibet1smaill6perm: '0.00', ibet1small4perm: '0.00'}],
    isUpdate: false,
    resultByItem: {},
    reviewResult: {},
    reviewResultToto: {
        "confirmed": false,
        winUsers: []
    },
    listResultTOTO: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_DRAW_DATE:
            return {
                ...state,
                listDrawDate: payload
            }
        case GET_ALL_LIST_RESULT_TOTO:
            return {
                ...state,
                listResultTOTO: payload
            }
        case GET_ALL_LIST_RESULT:
        case GET_RESULT_BY_DATE:
            return {
                ...state,
                listResultByDate: payload
            }
        case GET_RESULT_BY_NUMBER:
            return {
                ...state,
                listResultByNumber: payload
            };
        case CREATE_NEW_RESULT_ALL_NUMBER:
            return {
                ...state,
                resultByItem: payload,
                isUpdate: !state.isUpdate
            }
        case CREATE_NEW_RESULT_TOTO:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        case GET_REVIEW_RESULT:
            return {
                ...state,
                reviewResult: payload
            }
        case GET_REVIEW_RESULT_TOTO:
            console.log('payload====', payload);
            return {
                ...state,
                reviewResultToto: payload
            }
        case CONFIRM_RESULT:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
