import {DRAWDAY_LOG, GET_DRAW_DATE, GET_DRAW_DAYS, UPDATE_DRAW_DAYS} from "../../actions/type";


const initialState = {
    drawDays:[],
    logChanges: [],
    isUpdated: false,
    drawDate: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DRAW_DAYS:
            return {
                ...state,
                drawDays: payload
            }
        case DRAWDAY_LOG:
            return {
                ...state,
                logChanges: payload
            }
        case UPDATE_DRAW_DAYS:
            return {
                ...state,
                isUpdated: !state.isUpdated
            }
        case GET_DRAW_DATE:
            return {
                ...state,
                drawDate: payload
            }
        default:
            return state;
    }
}
