import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles, withStyles} from "@mui/styles";
import MuiListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {selectItem} from "actions/common";
import Grid from "@mui/material/Grid";
import {BREAK_POINT_FOR_LAPTOP} from "../../../constants/constants";
import Typography from "@mui/material/Typography";
import {getListMenu} from "../../../actions/auth";
import "../../../assets/color.css";

const useStyles = makeStyles({
    container: {},
    title2: {
        height: '20px',
        margin: "40px 0 20px 16px !important",
        backgroundColor: 'transparent',
        padding: '0 16px !important',
        cursor: 'pointer'
    },
    mainMenu: {
        lineHeight: 4,
        opacity: 0.8,
        fontSize: '14px',
        fontWeight: 'bolder',
        color: 'var(--color132)'
    },
    closeTitle: {
        marginBottom: "2px",
        backgroundColor: 'var(--color3)',
        borderTopRightRadius: "30px",
        color: 'var(--color3)'
    },
    root: {
        padding: "40px",
        height: "500px",
    },
    paging: {
        "& .Mui-selected": {
            color: "var(--color2)!important",
            backgroundColor: "var(--color2)!important",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px'
        },
        "& .MuiPaginationItem-root": {
            color: "var(--color1)",
            backgroundColor: "var(--color1)",
            minWidth: '1px',
            fontSize: '1px',
            height: '8px',
            margin: '10px'
        }
    },
    tableContainer: {
        "&.Mui-selected": {
            backgroundColor: "var(--color18)54!important"
        },
        "&.MuiTableRow-hover": {
            "&:hover": {
                backgroundColor: "var(--color18)21!important"
            }
        }
    },
    dialogCustomizedWidth: {
        maxWidth: "750px!important",
        overflow: "hidden",
        borderRadius: '20px!important',
        "& .MuiInputBase-root": {
            borderRadius: '40px',
            height: '40px',
            "& svg": {
                color: "var(--color18)",
            },
            "& fieldset": {
                borderColor: 'var(--color18)'
            }
        }
    },
    titleDialog: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    button: {
        borderRadius: '40px!important',
        backgroundColor: 'var(--color18)!important',
        height: "40px"
    },
    scrollBar: {
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color57)",
        },
        "&::-webkit-scrollbar": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color58)",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            width: "5px",
            backgroundColor: "var(--color58)"
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50px',
        backgroundAttachment: 'local, local, scroll, scroll',
        position: "relative",
        '@media screen and (min-width: 1024px) and (max-height: 900px)': {
            maxHeight: '90vh',
        },
    },
    menuItem: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: '16px !important',
        letterSpacing: '0.03em !important',
        color: 'var(--color7) !important',
    },
    menuItemHeading: {
        color: 'var(--color72) !important',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
        lineHeight: '14px !important',
    },
    menuItemDivide: {
        borderColor: 'var(--color15) !important',
    },
});

const ListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "var(--color131)!important",
            borderTopRightRadius: "20px!important",
            borderBottomRightRadius: "20px!important",
            width: "95%",
        },
        "&$selected": {},
        "&$selected:hover": {
            backgroundColor: "var(--color79)",
        },
    },
    selected: {},
})(MuiListItem);

const MainListItems = ({
                           user,
                           selectItem,
                           selectedItem,
                           toggleDrawer,
                           listLeftMenu
                       }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    const [selectedMenu, setSelectedMenu] = useState('main');

    useEffect(() => {
        let menu = '';
        switch (selectedIndex) {
            case 'profile':
            case 'result':
            case 'announcement':
                menu = 'main';
                break;
            case 'simple-bet':
            case 'mass-bet':
            case 'mass-bet-two':
            case 'wildcard-bet':
            case 'saved-bet':
            case 'fixed-bet':
            case 'upload-data':
            case 'telegram-help':
            case 'betting-history':
                menu = 'betting';
                break;
            case 'profit-loss':
            case 'full-report':
            case 'placeout':
            case 'strike':
            case 'stake-file-info':
            case 'current-stake-info':
                menu = 'company-report';
                break;
            case 'report-full':
            case 'placeout-report':
            case 'summary-report':
            case 'bet-detail':
            case 'telegram-detail':
            case 'strike-detail':
            case 'saved-detail':
            case 'fixed-detail':
            case 'deleted-detail':
                menu = 'report';
                break;
            case 'sub-account':
            case 'downline':
            case 'statement':
            case 'payment':
            case 'online-list':
            case 'fighting-history':
            case 'ticket-fighting':
                menu = 'account';
                break;
            case 'loginLog':
            case 'systemLog':
            case 'transaction':
            case 'draw-date':
                menu = 'view-log';
                break;
            default:
                return 'main'
        }
        setSelectedMenu(menu);

    }, [selectedIndex]);

    useEffect(() => {
        setSelectedIndex(selectedItem);
    }, [selectedItem]);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    const handleChooseMenu = (value) => {
        setSelectedMenu(value);
    }
    return (
        <div>
            {toggleDrawer && (
                <div className={classes.container}>
                    <Fragment>
                        <Grid
                            {...width > BREAK_POINT_FOR_LAPTOP ? {height: '900px'} : {height: '90vh'}}
                            sx={{overflow: 'auto', width: '240px', paddingBottom: '50px'}}
                            className={classes.scrollBar}
                        >
                            {listLeftMenu?.length > 0 && listLeftMenu?.map((item) => (
                                <>
                                    <Box p={2} mb={2} className={classes.title2}
                                         onClick={() => handleChooseMenu('main')}>
                                        <Typography variant="subtitle1"
                                                    className={classes.mainMenu}>{t(item?.menuName)}</Typography>
                                    </Box>
                                    {
                                        item?.subMenu?.map((sub) =>
                                            sub.subMenuCode === 'TICKET_FIGHTING' && user?.level === 'ADMIN' ? null :
                                                (
                                                    <ListItem
                                                        button
                                                        component={Link}
                                                        to={`${sub?.subMenuLink}`}
                                                        selected={selectedIndex === sub?.subMenuCode}
                                                    >
                                                        <ListItemText primary={
                                                            <Typography ml={2} variant='subtitle1'
                                                                        sx={{fontSize: '14px'}}>
                                                                {t(sub?.subMenuName)}
                                                            </Typography>}/>
                                                    </ListItem>
                                                )
                                        )
                                    }
                                </>
                            ))}
                        </Grid>
                    </Fragment>
                </div>
            )}
        </div>
    )
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    selectedItem: state.common.menuItemSelected,
    listLeftMenu: state.auth.listLeftMenu
});

export default connect(mapStateToProps, {
    selectItem,
    getListMenu
})(MainListItems);