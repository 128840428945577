import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,
    CHANGE_LOGOUT_STATUS,
    SET_FIRST_LOGIN,
    FLAG_FORGOT_PASSWORD_SUCCESS,
    FLAG_RESET_PASSWORD_SUCCESS,
    VERIFY_TOKEN_RESET_PASSWORD_SUCCESS,
    FLAG_VERIFY,
    FLAG_MAIL_EXPIRED,
    TOGGLE_LEFT_SIDE_BAR,
    UPDATE_PHONE_PROFILE,
    LOGIN_PASSED,
    RESET_FAIL_MSG,
    VERIFY_CODE_FAIL,
    GET_LEFT_MENU, TOKEN_EXPIRED
} from "../actions/type";

import {
    getLocalToken,
    setLocalToken,
    removeLocalToken,
} from "../config/globalStatement";
const initialState = {
    token: getLocalToken(),
    isAuthenticated: null,
    loading: true,
    logoutStatus: false,
    errorMsg: "",
    isLoginFailed: false,
    isTokenExpired: false,
    isTokenError: false,
    isInternalError: false,
    user: {
        id: "",
        username: "",
        phone1: "",
        phone2: "",
        phone3: "",
        nickName: "",
        uploadDataCode: "",
        agentCreditLimit: 0,
        creditUsed: 0,
        creditAvaiable: 0,
        autoResetBalance: true, // get from permission
        status: "Active",
        createAgent: true, // get from permission
        deleteBet: true, // get from permission
        createPayment: true, // get from permission
        statement: true, // get from permission
        fakePass: true, // get from permission
        androidApp: true, // get from permission
        saveBet: true, // get from permission
        loginHistory: true, // get from permission
        placeBets: true, // get from permission
        remarks: '',
        permission: [],
        mfaEnabled: false
    },
    userSetting: {
        tktRate: {big: '1.6', small: '0.7'},
        tktRebate: '11.00',
        firstPrize: '0.00',
        secondPrize: '0.00',
        thirdPrize: '0.00',
        strPrize: '0.00',
        consoPrize: '0.00',
        coPL: '80',
        groupPL: '0.00',
        groupIntake: '0.00',
        shareCoExp: true
    },
    isLoginSuccess: false,
    firstLogin: undefined,
    isForgotPasswordSuccess: false,
    isResetPasswordSuccess: false,
    isMailExpired: false,
    isVerifyFail: '',
    mailReset: '',
    loginFailedMess: '',
    lefSideBarControl: true,
    isUpdate: false,
    passedLogin: {},
    failOtpMessage: '',
    listLeftMenu: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: !!payload?.id,
                loading: false,
                user: payload,
            };
        case RESET_FAIL_MSG:
            return {
                ...state,
                failOtpMessage: ''
            }
        case LOGIN_PASSED:
            const newToken = payload?.token;
            if(newToken) {
                removeLocalToken();
                setLocalToken(newToken);
            }
            return {
                ...state,
                loading: false,
                isLoginSuccess: payload?.isLoginSuccess,
                passedLogin: payload,
                loginFailedMess: '',
                failOtpMessage: '',
                token: newToken || state.token,
            }
        case LOGIN_SUCCESS:
            removeLocalToken();
            setLocalToken(payload.token);
            return {
                ...state,
                ...payload,
                token:payload.token,
                isAuthenticated: true,
                loading: false,
                isTokenExpired: false,
                logoutStatus: false,
                isLoginSuccess: true
            };
        case VERIFY_CODE_FAIL:
            removeLocalToken();
            return {
                ...state,
                isLoginFailed: true,
                failOtpMessage: payload
            };
        case LOGIN_FAIL:
            removeLocalToken();
            return {
                ...state,
                isLoginFailed: true,
                loginFailedMess: payload
            };
        case AUTH_ERROR:
        case LOGOUT:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                isLoginFailed: false,
                isTokenExpired: false,
                isInternalError: false,
                logoutStatus: true,
            };
        case CHANGE_LOGOUT_STATUS:
            removeLocalToken()
            return {
                ...state,
                logoutStatus: true,
            };
        case SET_FIRST_LOGIN:
            return {
                ...state,
                firstLogin: payload,
            };
        case FLAG_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isForgotPasswordSuccess: payload,
            };
        case FLAG_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isResetPasswordSuccess: payload,
            };
        case VERIFY_TOKEN_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                mailReset: payload,
            };
        case FLAG_MAIL_EXPIRED:
            return {
                ...state,
                isMailExpired: payload,
            };
        case FLAG_VERIFY:
            return {
                ...state,
                isVerifyFail: payload,
            };
        case TOGGLE_LEFT_SIDE_BAR:
            return {
                ...state,
                lefSideBarControl: payload
            }
        case UPDATE_PHONE_PROFILE:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        case GET_LEFT_MENU:
            return {
                ...state,
                listLeftMenu: payload
            }
        case TOKEN_EXPIRED:
            return {
                ...state,
                token: null,
                isTokenExpired: true,
                logoutStatus: true
            }
        default:
            return state;
    }
}
