import React from "react";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../../assets/color.css";
const useStyles = makeStyles({
    titleContent: {
        fontSize: '30px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.05em !important',
        color: 'var(--color7) !important',

        "&::before": {
            content: '""',
            display: "inline-block",
            backgroundColor: " var(--color119)",
            marginRight: "20px",
            width: "7px",
            height: "30px",
            verticalAlign: "-10%",
        },
    },
});
const TitlePage = ({title}) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} paddingBottom={'15px'}>
            <Typography variant="h5" className={classes.titleContent}>
                {title}
            </Typography>
        </Grid>
    );
}
export default TitlePage;