import {BETTING_HISTORY, BETTING_HISTORY_TOTO, CANCEL_BETTING} from "../../actions/type";

const initialState = {
    listBettingHistory: [],
    listBettingHistoryTOTO: [],
    isUpdate: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BETTING_HISTORY:
            return {
                ...state,
                listBettingHistory: payload
            }
        case BETTING_HISTORY_TOTO:
            return {
                ...state,
                listBettingHistoryTOTO: payload
            }
        case CANCEL_BETTING:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
