import axios from "axios";
import {GET_LIST_REPORT_BY_ID, GET_LIST_REPORT_TOTO_BY_ID} from "../type";
import {REACT_API_LOCATION} from "../../constants/constants";
export const getListReportById = (params) => async (dispatch) =>{
    try {
        const res = await axios.get(`${REACT_API_LOCATION}/backoffice-service/report/SG4D?userId=${params?.userId}&fromDate=${params?.fromDate || ''}&toDate=${params?.toDate || ''}`);
        dispatch({
            type: GET_LIST_REPORT_BY_ID,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}
export const getListReportTOTOById = (params) => async (dispatch) =>{
    try {
        const res = await axios.get(`${REACT_API_LOCATION}/backoffice-service/report/TOTO?userId=${params?.userId}&fromDate=${params?.fromDate || ''}&toDate=${params?.toDate || ''}`);
        dispatch({
            type: GET_LIST_REPORT_TOTO_BY_ID,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}