import {connect} from "react-redux";
import {
    Box,
    Button,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {selectItem} from "../../../actions/common";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CALENDAR_DATE_FORMAT, GetDetectBrowserInfo} from "../../../constants/constants";
import {ItemPanelPaper} from "../../../components/PanalPaper/ItemPanelPaper";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {makeStyles} from "@mui/styles";
import {getListReportById} from "../../../actions/reportManagement/fullReport";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import {convertDateSubmit} from "../../../utils/common";
import {useOutlinedInputStyles} from "../../../components/input/useOutlinedInputStyles";
import {useHistory, useLocation} from "react-router-dom";
import {handleUrl} from "../../../utils/setUrl";
import qs from "qs";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const useStyles = makeStyles({
    styleTd: {
        borderColor: 'var(--color9)',
        fontSize: '14px!important',
        textAlign: 'center !important',
        background: 'var(--color3)',
        padding: '5px 2px !important'
    },
    styleTh: {
        fontSize: '12px!important',
        fontWeight: '700!important',
        color: 'var(--color3)!important',
        borderColor: 'var(--color9)!important',
        background: 'var(--color125)',
        textAlign: 'center !important',
        padding: '5px !important'
    },
    formInput: {
        borderRadius: '3px !important',
        height: '35px !important',
        padding: '0 5px !important',
        width: '100% !important',
        maxWidth: '100% !important',
        background: '#fff',
        minWidth: '50px',
        fontSize: '14px !important',
        fontWeight: '700 !important',
        lineHeight: '1 !important',
        letterSpacing: '0.05em !important',
        color: '#111',
        '& .MuiOutlinedInput-input': {
            padding: '6px 2px !important'
        },
        "& input::placeholder": {
            color: "#cecec9",
            fontWeight: '500'
        }
    },
    textLink: {
        color: 'var(--color90)!important',
        textDecoration: 'underline',
        cursor: 'pointer',
        borderColor: 'var(--color9)',
        fontSize: '14px!important',
        textAlign: 'center !important',
        background: 'var(--color3)',
        padding: '5px 2px !important'
    },
    breadcrumbItem: {
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        letterSpacing: '0.05em !important',
        lineHeight: '14px !important',
        color: 'var(--color90)!important',
        textDecoration: 'underline',
        cursor: 'pointer',
    }
})
const FullReport4D = ({selectItem, getListReportById, fullReportList, user}) => {
    const {t} = useTranslation();
    const {isDesktop, isLaptop, isTablet} = GetDetectBrowserInfo();
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const dateFilter = new Date();
    const [filterDate, setFillerDate] = useState({
        endDate: dateFilter.setDate(dateFilter.getDate()),
        startDate: dateFilter.setDate(dateFilter.getDate() - 14)

    });
    const dateRange = new Date();
    const [rangeDate, setRangeDate] = useState({
        minDate: dateRange.setDate(new Date(filterDate?.endDate)?.getDate() - 30),
        maxDate: dateRange.setDate(new Date(filterDate?.startDate)?.getDate() + 60)
    });
    const [userFilter, setUserFilter] = useState('');
    const history = useHistory();
    const location = useLocation();
    const locationValue = handleUrl(
        qs.parse(location.search, { ignoreQueryPrefix: true}) || {}
    )
    useEffect(() => {
        selectItem("telegram-detail");
        if(locationValue?.userId || user?.userId) {
            handleGetData(locationValue?.userId || user?.userId);
        }
    }, [locationValue?.userId, user?.userId]);
    useEffect(() => {
        handleGetData()
        const date = new Date();
        setRangeDate({
            minDate: date?.setDate(new Date(filterDate?.endDate)?.getDate() - 30),
            maxDate: date?.setDate(new Date(filterDate?.startDate)?.getDate() + 60)
        })
    }, [filterDate]);

    const handleGetData = (userData) => {
        const params = {
            userId: userData || (userFilter == '' ? (locationValue?.userId || user?.userId) :  userFilter ),
            fromDate: convertDateSubmit(filterDate.startDate),
            toDate: convertDateSubmit(filterDate.endDate)
        }
        params?.userId && getListReportById(params)
    }
    const handleChangeFilterDate = (value, name) => {
        setFillerDate({
            ...filterDate,
            [name]: value
        })
    }
    const handleGotoDownline = (id, level) => {
        let url = `/report/full-report?tab=4d&userId=${id}&${level}=${id}${locationValue?.CO ?`&CO=${locationValue?.CO}` : ''}${locationValue?.MA ? `&MA=${locationValue?.MA}` : ''}${locationValue?.AG ? `&AG=${locationValue?.AG}` : ''}${locationValue?.PL ? `&PL=${locationValue?.PL}`: ''}`;
        history.push(url);
        handleGetData(id);
    }
    const handleClear = () => {
        const newDate = new Date()
        setFillerDate({
            endDate: newDate.setDate(newDate.getDate()),
            startDate: newDate.setDate(newDate.getDate() - 14)
        })
        setUserFilter('');
        let url = `/report/full-report?tab=4d`;
        history.push(url);
    }
    const handleClick = (userId, level) => {
        if(level==='CO') {
            let url = `/report/full-report?tab=4d&userId=${userId}&CO=${userId}`;
            history.push(url);
        }
        if(level==='MA') {
            let url = `/report/full-report?tab=4d&userId=${userId}${locationValue?.CO ?`&CO=${locationValue?.CO}` : ''} &MA=${userId}`;
            history.push(url);
        }
        if(level==='AG') {
            let url = `/report/full-report?tab=4d&userId=${userId}${locationValue?.CO ?`&CO=${locationValue?.CO}` : ''}${locationValue?.MA ? `&MA=${locationValue?.MA}` : ''}&AG=${userId}`;
            history.push(url);
        }
        if(level==='PL') {
            let url = `/report/full-report?tab=4d&userId=${userId}${locationValue?.CO ?`&CO=${locationValue?.CO}` : ''}${locationValue?.MA ? `&MA=${locationValue?.MA}` : ''}${locationValue?.AG ? `&AG=${locationValue?.AG}` : ''}&PL=${userId}`;
            history.push(url);
        }
    }
    return (
        <Fragment>
            <Box pt={2} px={isLaptop || isDesktop ? 2 : 0}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <ItemPanelPaper padding={isDesktop || isLaptop || isTablet ? 4 : 1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={(isDesktop || isLaptop || isTablet) ? 2.5 : 12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={1}>
                                                    <DesktopDatePicker
                                                        label={t("From date")}
                                                        inputFormat={CALENDAR_DATE_FORMAT}
                                                        minDate={rangeDate.minDate}
                                                        maxDate={filterDate.endDate}
                                                        value={filterDate.startDate}
                                                        onChange={(newValue) => handleChangeFilterDate(newValue, 'startDate')}
                                                        renderInput={(params) => <TextField {...params} sx={{
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border: '1px solid var(--color14)',
                                                                borderRadius: '3px'
                                                            },
                                                            "& .MuiInputBase-root": {
                                                                height: '35px'
                                                            }
                                                        }}/>}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={(isDesktop || isLaptop || isTablet) ? 2.5 : 12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={1}>
                                                    <DesktopDatePicker
                                                        label={t("To date")}
                                                        inputFormat={CALENDAR_DATE_FORMAT}
                                                        minDate={filterDate.startDate}
                                                        maxDate={rangeDate.maxDate}
                                                        value={filterDate.endDate}
                                                        onChange={(newValue) => handleChangeFilterDate(newValue, 'endDate')}
                                                        renderInput={(params) => <TextField {...params} sx={{
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                border: '1px solid var(--color14)',
                                                                borderRadius: '3px'
                                                            },
                                                            "& .MuiInputBase-root": {
                                                                height: '35px'
                                                            }
                                                        }}/>}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={isDesktop || isLaptop || isTablet ? 3 : 12}>
                                            <OutlinedInput
                                                value={userFilter}
                                                onChange={(event) => setUserFilter(event.target.value)}
                                                classes={outlinedInputClasses}
                                                className={classes.formInput}
                                                placeholder={'Account ID'}
                                            />
                                        </Grid>
                                        <Grid item xs={(isDesktop || isLaptop || isTablet) ? 3 : 12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.btnCustom}
                                                        sx={{
                                                            width:
                                                                isDesktop || isLaptop || isTablet
                                                                    ? "100px !important"
                                                                    : "100% !important",
                                                        }}
                                                        onClick={() => handleGetData()}
                                                    >
                                                        {t("View")}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.btnCustom}
                                                        sx={{
                                                            width:
                                                                isDesktop || isLaptop || isTablet
                                                                    ? "100px !important"
                                                                    : "100% !important",
                                                        }}
                                                        onClick={() => handleClear()}
                                                    >
                                                        {t("Clear")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        {locationValue?.CO &&
                                            <Typography className={classes.breadcrumbItem} color="inherit"
                                                        onClick={() => handleClick(locationValue?.CO, 'CO')}
                                            >
                                                {locationValue?.CO}
                                            </Typography>
                                        }
                                        {locationValue?.MA &&
                                            <Typography className={classes.breadcrumbItem} color="inherit"
                                                        onClick={() => handleClick(locationValue?.MA, 'MA')}
                                            >
                                                {locationValue?.MA}
                                            </Typography>
                                        }
                                        {locationValue?.AG &&
                                            <Typography className={classes.breadcrumbItem} color="inherit"
                                                        onClick={() => handleClick(locationValue?.AG, 'AG')}
                                            >
                                                {locationValue?.AG}
                                            </Typography>
                                        }
                                        {locationValue?.PL &&
                                            <Typography className={classes.breadcrumbItem} color="inherit"
                                                        onClick={() => handleClick(locationValue?.PL, 'PL')}
                                            >
                                                {locationValue?.PL}
                                            </Typography>
                                        }
                                    </Breadcrumbs>
                                </Grid>
                                {fullReportList?.payout?.totalAmount !== 0 ?
                                    <>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper} sx={{
                                                boxShadow: 'none',
                                                border: '1px solid var(--color13)',
                                                borderRadius: '3px'
                                            }}>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.styleTh} width={'30%'} rowSpan={2}>{t("Total Placeout")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Big")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Small")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Bet amount")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("[%]")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Payable")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Strike (Win Prize Amount)")}</TableCell>
                                                            {/*<TableCell className={classes.styleTh}>{t("Tkt[%]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Exp")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Tax")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Grp [$]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL [%]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL [$]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL Nett")}</TableCell>*/}
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Nett")}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalBig}</TableCell>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalSmall}</TableCell>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalAmount}</TableCell>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.commission}</TableCell>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalPayable}</TableCell>
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalStrike}</TableCell>
                                                            {/*<TableCell>{fullReportList?.payout?.tkt}</TableCell>*/}
                                                            {/*<TableCell>{fullReportList?.payout?.exp}</TableCell>*/}
                                                            {/*<TableCell>{fullReportList?.payout?.tax}</TableCell>*/}
                                                            {/*<TableCell>{fullReportList?.payout?.grp}</TableCell>*/}
                                                            {/*<TableCell>{fullReportList?.payout?.pl}</TableCell>*/}
                                                            {/*<TableCell>{fullReportList?.payout?.plNett}</TableCell>*/}
                                                            <TableCell className={classes.styleTd}>{fullReportList?.payout?.totalNett}</TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {fullReportList?.upline !== null
                                            &&
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper} sx={{
                                                    boxShadow: 'none',
                                                    border: '1px solid var(--color13)',
                                                    borderRadius: '3px'
                                                }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.styleTh} width={'15%'}>{t("Upline")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'15%'}>{t("Name")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Big")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Small")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Bet amount")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("[%]")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Payable")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Strike (Win Prize Amount)")}</TableCell>
                                                                <TableCell className={classes.styleTh} width={'10%'}>{t("Nett")}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                fullReportList?.upline !== null ?
                                                                    <TableRow>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.parentName}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.userName}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalBig}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalSmall}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalAmount}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.commission}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalPayable}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalStrike}</TableCell>
                                                                        <TableCell className={classes.styleTd}>{fullReportList?.upline?.totalNett}</TableCell>
                                                                    </TableRow>
                                                                    :
                                                                    <TableRow sx={{ cursor: "pointer" }}>
                                                                        <TableCell className={classes.styleTd} sx={{textAlign: 'center'}} colSpan={9}>
                                                                            <Typography>{t("No records found")}</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <TableContainer component={Paper} sx={{
                                                boxShadow: 'none',
                                                border: '1px solid var(--color13)',
                                                borderRadius: '3px'
                                            }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.styleTh} width={'15%'}>{fullReportList?.downlines?.[0]?.level}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'15%'}>{t("Name")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Big")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Small")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Bet amount")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("[%]")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Payable")}</TableCell>
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Strike (Win Prize Amount)")}</TableCell>
                                                            {/*<TableCell className={classes.styleTh}>{t("Tkt[%]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Exp")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Tax")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("Grp [$]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL [%]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL [$]")}</TableCell>*/}
                                                            {/*<TableCell className={classes.styleTh}>{t("PL Nett")}</TableCell>*/}
                                                            <TableCell className={classes.styleTh} width={'10%'}>{t("Nett")}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        { fullReportList?.downlines?.length >0 ?
                                                            fullReportList?.downlines?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell className={item?.level !== 'PL' ? classes.textLink : classes.styleTd}
                                                                               onClick={() => item?.level !== 'PL' && handleGotoDownline(item?.userId, item?.level)}>{item?.userId}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.userName}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.totalBig}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.totalSmall}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.totalAmount}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.commission}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.totalPayable}</TableCell>
                                                                    <TableCell className={classes.styleTd}>{item?.totalStrike}</TableCell>
                                                                    {/*<TableCell>{item?.tkt}</TableCell>*/}
                                                                    {/*<TableCell>{item?.exp}</TableCell>*/}
                                                                    {/*<TableCell>{item?.tax}</TableCell>*/}
                                                                    {/*<TableCell>{item?.grp}</TableCell>*/}
                                                                    {/*<TableCell>{item?.pl}</TableCell>*/}
                                                                    {/*<TableCell>{item?.plNett}</TableCell>*/}
                                                                    <TableCell className={classes.styleTd}>{item?.totalNett}</TableCell>
                                                                </TableRow>
                                                            ))
                                                            :
                                                            <TableRow sx={{ cursor: "pointer" }}>
                                                                <TableCell className={classes.styleTd} sx={{textAlign: 'center'}} colSpan={9}>
                                                                    <Typography>{t("No records found")}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </>
                                    :
                                    <Grid item xs={12} mt={3}>
                                        <Typography>{t("No records found")}</Typography>
                                    </Grid>


                                }


                            </Grid>
                        </ItemPanelPaper>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
    fullReportList: state.fullReport.fullReportList,
    user: state.auth.user
});
export default connect(mapStateToProps, {selectItem, getListReportById})(FullReport4D);