import {
    AUTH_ERROR,
    CHANGE_LOGOUT_STATUS, CHANGE_PASSWORD, EDIT_ALERT, ERROR_ALERT, GET_LEFT_MENU,
    LOGIN_FAIL, LOGIN_PASSED,
    LOGIN_SUCCESS,
    LOGOUT, RESET_FAIL_MSG, UPDATE_PHONE_PROFILE,
    USER_LOADED, VERIFY_CODE_FAIL
} from "./type";
import {getLocalToken, setLocalToken} from "../config/globalStatement";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import {REACT_API_LOCATION} from "../constants/constants";

export const login = (username, password) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": ""
        },
        withCredentials: false,
    };
    const body = JSON.stringify({ "userName": username, "password": password });
    try {
        console.log('REACT_API_LOCATION=====', REACT_API_LOCATION)
        const res = await axios.post(`${REACT_API_LOCATION}/backoffice-service/auth/login`,
            body,
            config
        );
        const data = {
            ...res?.data?.data,
            isLoginSuccess: res?.data?.data?.code === 'LOGIN_SUCCESS'
        }
        dispatch({
            type: LOGIN_PASSED,
            payload: data,
        });
        if(data?.token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            setLocalToken(data.token)
        }
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error?.response?.data?.msg
        });
    }
};
export const verifyOTP = (data) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": ""
        },
        withCredentials: false,
    };
    const body = JSON.stringify(data);
    try {
        const res = await axios.post(`${REACT_API_LOCATION}/backoffice-service/auth/verifyOTP`,
            body,
            config
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res?.data?.data,
        });
        loadUser()
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.data.token}`;
        setLocalToken(res?.data?.data?.token)
    } catch (error) {
        dispatch({
            type: VERIFY_CODE_FAIL,
            payload: error?.response?.data?.msg
        });
    }
};

export const loadUser = () => async (dispatch) =>{
    if(localStorage.token) {
        setAuthToken()
    }
    const token = getLocalToken();
    try {
        let userData = {};
        if(token !== null) {
            // call API get profile here
            const res1 = await axios.get(`${REACT_API_LOCATION}/backoffice-service/profile`);
            const dataRes = res1?.data?.data;
            const isSubaccount = dataRes?.isSubAccount;
            userData = {
                        id: dataRes?.userId,
                        userId: dataRes?.userId,
                        username: dataRes?.userName,
                        phone1: dataRes?.phone1,
                        phone2: dataRes?.phone2,
                        phone3: dataRes?.phone3,
                        nickName: dataRes?.nickName,
                        uploadDataCode: dataRes?.uploadDataCode,
                        agentCreditLimit: dataRes?.agentCreditLimit,
                        creditUsed: dataRes?.creditUsed,
                        creditAvaiable: dataRes?.creditAvailable,
                        autoResetBalance: dataRes?.permission?.autoResetBalance, // get from permission
                        status: dataRes?.status,
                        createAgent: dataRes?.permission?.createAgent, // get from permission
                        deleteBet: dataRes?.permission?.deleteBet, // get from permission_ still not handle in UI
                        createPayment: isSubaccount ? dataRes?.permission?.accountSettings?.payments : true, // get from permission
                        statement: isSubaccount ? dataRes?.permission?.accountSettings?.statement : true, // get from permission
                        fakePass:  dataRes?.permission?.fakePass, // get from permission
                        androidApp:  dataRes?.permission?.androidApp, // get from permission still not handle yet, maybe we dont have android app
                        saveBet: dataRes?.permission?.saveBets, // get from permission
                        loginHistory: dataRes?.permission?.loginHistory, // get from permission
                        systemHistory: dataRes?.permission?.systemHistory,
                        placeBets: dataRes?.permission?.placeBets, // get from permission
                        remarks: dataRes?.remarks,
                        permission: dataRes?.permission, // get from permission
                        downline:  isSubaccount ? dataRes?.permission?.accountSettings?.downLines: true, // get from permission
                        onlineList:  isSubaccount ? dataRes?.permission?.accountSettings?.onlineList : true, // get from permission
                        level: dataRes?.level,
                        companyReport:  dataRes?.permission?.companyReport, // get from permission
                        fullReport:  isSubaccount ? dataRes?.permission?.reports?.fullReport : true,
                        placeoutReport:  isSubaccount ? dataRes?.permission?.reports?.placeoutReport: true,
                        summaryReport:  isSubaccount ? dataRes?.permission?.reports?.summaryReport : true ,
                        betDetail:  isSubaccount ? dataRes?.permission?.reports?.betDetail : true,
                        strikeReport:  isSubaccount ? dataRes?.permission?.reports?.strikeReport : true,
                        saveDetail:  isSubaccount ? dataRes?.permission?.reports?.saveDetail : true,
                        fixedDetail:  isSubaccount ? dataRes?.permission?.reports?.fixedDetail : true,
                        mfaEnabled: dataRes?.mfaEnabled
            }
        }
        dispatch({
            type: USER_LOADED,
            payload: userData
        })

    } catch (error) {
        dispatch({type: AUTH_ERROR})
    }
}
export const keepAlive = () => async (dispatch) =>{
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: false,
    };
    try {
        await axios.post(`${REACT_API_LOCATION}/backoffice-service/auth/keepAlive`,'',
            config
        );
    } catch (error) {
        dispatch({type: AUTH_ERROR})
    }
}
export const callLogout = () => async () => {
    try {
        // await axios.get("API logout");
    } catch (error) {
        console.log(error)
    }
}
export const changeLogoutStatus = () => (dispatch) => {
    dispatch({ type: CHANGE_LOGOUT_STATUS });
};
export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT });
};

    export const editPhoneProfile = (data) => async (dispatch) =>{
        try {
            const body = JSON.stringify(data);
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: false,
            };
            await axios.put(`${REACT_API_LOCATION}/backoffice-service/profile`,body, config);
            dispatch({
                type: UPDATE_PHONE_PROFILE
            })
            dispatch({
                type: EDIT_ALERT,
                payload: "Edit Success",
            })
        } catch (error) {
            dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
        }
    }
export const changePassword = (data) => async (dispatch) =>{
    try {
        const body = JSON.stringify(data);
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
        };
        await axios.post(`${REACT_API_LOCATION}/backoffice-service/profile/changePassword`,body, config);
        dispatch({
            type: CHANGE_PASSWORD
        })
        dispatch({
            type: EDIT_ALERT,
            payload: "Edit Success",
        })
    } catch (error) {
        dispatch({ type: ERROR_ALERT, payload: error.response.data?.msg });
    }
}
export const resetFailMessage = () => (dispatch) => {
        dispatch({
            type: RESET_FAIL_MSG
        })
}
export const getListMenu = () => async (dispatch) =>{
    try {
        // Call API
        const res = await axios.get(`${REACT_API_LOCATION}/backoffice-service/leftMenu`);
        dispatch({
            type: GET_LEFT_MENU,
            payload: res?.data?.data
        })
    } catch (error) {
        console.log(error)
    }
}