import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, withStyles } from "@mui/styles";
import { GetDetectBrowserInfo } from 'constants/constants';
import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from 'classnames';
import Language from "../../../components/menu/languages/Language";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import {Avatar, Drawer, Typography} from "@mui/material";
import MainListItems from "../layout/ListItems";
import {styled} from "@mui/material/styles";
import "../../../assets/color.css";
import {changeLogoutStatus} from "../../../actions/auth";
import avatar_default from "../../../assets/avatar_default.jpg"
const useStyles = makeStyles({
    box: {
        backgroundColor: "var(--color133)",
        height: "60px",
        marginBottom: "7px",
        padding: "18px",
        textAlign: "center",

        '&.drawer-menu-icon': {
            padding: '0.8rem',
            marginBottom: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all ease 325ms',
            willChange: 'opacity',

            '&:hover, &:active': {opacity: 0.8},
        },
    },
    drawerMenuIcon: {
        '--size': '1.6rem',
        width: 'var(--size)',
        height: 'var(--size)',
    },
    name: {
        color: "var(--color3)",
        display: 'flex',
        alignItems: 'center'
    },
    displayText: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
        letterSpacing: '0.01em !important',
        textTransform: 'initial'
    },
    button: {
        display: "flex",
        "&:hover": {
            backgroundColor: "var(--color80)",
        },
    },
    headerButton: {
        minWidth: "unset !important",
        padding: "0 !important"
    }
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        "& .MuiListItemText-primary": {
            padding: theme.spacing(1),
            textAlign: 'center',
            border: 'var(--color1)'
        },
    },
}))(MenuItem);

//style menu
const StyledMenu = withStyles({
    paper: {
        border: "1px solid var(--color13)",
        marginTop: "0.8rem",
        width: "250px",
    },
})((props) => <Menu elevation={0}  {...props} />);

const User = ({isLogin, user, changeLogoutStatus}) => {
    const history = useHistory();
    const classes = useStyles();
    const logOut = async () => {
        // await callLogout()
        handleSignOut()
    };

    const handleSignOut = () => {
        changeLogoutStatus();
        return history.push("/login");
    };
    const {t} = useTranslation();

    const { isDesktop, isLaptop, isTablet } = GetDetectBrowserInfo();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onToggleDrawerMenu = () => {
        setIsDrawerOpened(!isDrawerOpened);
    };
    return (
        <div>
            <Grid container
                  alignItems="center" >
                { !isLogin && !(isDesktop || isLaptop) &&
                    <Grid
                        item
                        justifyContent={'center'}
                        alignItems={'center'}
                        xs={(isTablet) ? 5: 3}
                    >
                        <Box className={`${classes.box} drawer-menu-icon`} onClick={() => onToggleDrawerMenu()}>
                            {
                                isDrawerOpened
                                    ? <PlaylistRemoveOutlinedIcon className={classes.drawerMenuIcon}/>
                                    : <TocOutlinedIcon className={classes.drawerMenuIcon}/>
                            }
                        </Box>
                        <Drawer open={isDrawerOpened} onClose={() => onToggleDrawerMenu()}
                            sx={{
                                "& .MuiDrawer-paper": {
                                    top: "3.8rem"
                                }
                            }}
                        >
                            <MainListItems toggleDrawer={isDrawerOpened} isFullHeight={true}/>
                        </Drawer>
                    </Grid>
                }
                <Grid item xs={(isDesktop || isLaptop)? 12 : isTablet ? 7 : 9 }>
                    <Grid container alignItems="center" justifyContent={'flex-end'}>
                        <Grid
                            item
                            textAlign={'center'}
                        >
                            <Box>
                                <Language/>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            textAlign={"center"}
                            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft : "30px", paddingRight : "30px"}}>
                            <Button onClick={handleClick} className={classNames(classes.button , classes.headerButton)}>
                                <Box className={classes.name} display={'flex'}>

                                    <Typography className={classes.displayText} sx={{color: 'var(--color3)'}}>{t('Credit')}: {user?.level === 'ADMIN' ? 'Unlimit' : (new Intl.NumberFormat().format(user?.creditAvaiable) || 0)}$</Typography>
                                    <StyledAccount>
                                        <Avatar src={avatar_default} alt="photoURL" sx={{
                                            width: '30px',
                                            height: '30px'
                                        }}/>
                                    </StyledAccount>
                                </Box>
                            </Button>
                        </Grid>

                        {/*{ !isLogin &&*/}
                        {/*    (isDesktop || isLaptop)*/}
                        {/*    && (<Grid xs={isDesktop ? 2 : 3} item justifyContent={"center"} sx={{backgroundColor: "rgb(102 133 97)"}}>*/}
                        {/*        <Box className={classes.box}>*/}
                        {/*            <Typography className={classes.displayText}>Username: {user?.orgName}</Typography>*/}
                        {/*        </Box>*/}
                        {/*    </Grid>)*/}
                        {/*}*/}
                    </Grid>
                </Grid>

            </Grid>
            {!isLogin &&
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 20,
                        horizontal: 30,
                    }}
                >
                    <Box borderBottom={1} borderColor={'var(--color1)'}>
                        <StyledMenuItem
                            component={Link}
                            to="/main/profile"
                        >
                            <ListItemText primary={t("My Information")} sx={{letterSpacing: '0.05em'}}/>
                        </StyledMenuItem>
                    </Box>
                    <StyledMenuItem onClick={() => logOut()}>
                        <ListItemText sx={{color: 'var(--color83)'}} primary={t("Log out")}/>
                    </StyledMenuItem>
                </StyledMenu>
            }

        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {changeLogoutStatus})(User);
const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 2.5),
    borderRadius: '50%',
    backgroundColor: 'transparent',
}));
