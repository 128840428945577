import React, {lazy, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./pages/GlobalManagement/routing/PrivateRoute";
import Layout from "./pages/GlobalManagement/layout/Layout";
import {getListMenu, loadUser} from "./actions/auth";
import FullReports from "./pages/CompanyReport/FullReport/FullReports";

const Downline = lazy(() => import  ("./pages/AccountManagement/Downline/Downline"));
const Result = lazy(() => import  ("./pages/Result/Result"));
const OnlineList = lazy(() => import  ("./pages/AccountManagement/OnlineList/OnlineList"));
const Announcement = lazy(() => import  ("./pages/Announcement/Announcement"));
const BettingHistory = lazy(() => import  ("./pages/CompanyReport/BettingHistory/BettingHistory"));
const DownlineDetail = lazy(() => import  ("./pages/AccountManagement/Downline/DownlineDetail"));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Transaction = lazy(() => import('./pages/LogManagement/Transaction/Transaction'));
const SetupMFA = lazy(() => import('./pages/GlobalManagement/auth/SetupMFA'));
const NoMatch = lazy(() => import('./pages/GlobalManagement/routing/NoMatch'));
const SignInPage = lazy(() => import('./pages/GlobalManagement/auth/Login'));
const MainPage = lazy(() => import('./pages/GlobalManagement/mainPage/MainPage'));
const FightingHistory = lazy(() => import('./pages/AccountManagement/FightingHistory/FightingHistory'));
const LoginLog = lazy(() => import('./pages/LogManagement/LoginLog/LoginLog'));
const SystemLog = lazy(() => import('./pages/LogManagement/SystemLog/SystemLog'));
const MenuSettingPage = lazy(() => import('./pages/ConfigManagement/MenuManagement/Menu'));
const DrawDate = lazy(() => import('./pages/LogManagement/DrawDate/DrawDate'));

const MenuComponentMap = {
    MAIN: MainPage,
    PROFILE: Profile,
    RESULT: Result,
    ANNOUNCEMENTS: Announcement,
    BETTING_HISTORY: BettingHistory,
    DOWNLINE: Downline,
    ONLINE_LIST: OnlineList,
    MENU: MenuSettingPage,
    DRAW_DAYS: DrawDate,
    LOGIN_LOGS: LoginLog,
    SYSTEM_LOGS: SystemLog,
    TRANSACTION_LOGS: Transaction,
    TICKET_FIGHTING: FightingHistory,
    // below items will be replaced by corresponding Component later
    COMPANY_PROFIT_LOSS: NoMatch,
    SUMMARY_REPORT: NoMatch,
    BET_DETAIL: NoMatch,
    TELEGRAM_DETAIL: NoMatch,
    SAVED_DETAIL: NoMatch,
    FIXED_DETAIL: NoMatch,
    DELETED_DETAIL: NoMatch,
    FULL_REPORT: FullReports
};

const AppRouter = () => {

    const token = useSelector(state => state.auth?.token);
    const leftMenuList = useSelector(state => state.auth?.listLeftMenu);
    const user = useSelector(state => state.auth?.user);
    const dispatch = useDispatch();

    useEffect(() => {
            if (token) {
                dispatch(loadUser());
                dispatch(getListMenu());
            } else if (!window.location.href.includes('/login')) {
                window.location.href = '/login';
            }
        }, [token]
    );

    const memoizedArray = useMemo(() => {

            let newArr = [];
            leftMenuList.map((leftMenu, index) => {
                const menuCode = leftMenu.menuCode;
                const MenuLayout = MenuComponentMap[menuCode];
                const subMenu = leftMenu.subMenu;
                if (MenuLayout) {
                    newArr = [...newArr, {
                        key: `${menuCode}_${index}`,
                        path: leftMenu.menuLink,
                        name: leftMenu.menuName,
                        MenuLayout: MenuLayout
                    }];

                }
                subMenu.map(sub => {
                    if ((sub?.subMenuCode === 'TICKET_FIGHTING' && user?.level !== 'ADMIN') || (sub?.subMenuCode !== 'TICKET_FIGHTING')) {
                        const SubLayout = MenuComponentMap[sub.subMenuCode];
                        newArr = [...newArr, {
                            key: `${menuCode}_${sub.subMenuCode}_${index}`,
                            path: sub.subMenuLink,
                            name: sub.subMenuName,
                            MenuLayout: SubLayout
                        }]
                    }

                })
            })
            return newArr;

    }, [leftMenuList, user]);
    return (
        <Router>
            <Switch>
                <Route exact path="/" key={'login-main'}>
                    <Redirect to={token ? '/main' : '/login'} key={'login-main'}/>
                </Route>
                <Route exact path="/login" component={SignInPage} key={'login'}/>
                <Route exact path="/setup-mfa" component={SetupMFA} key={'setup-mfa'}/>
                <PrivateRoute key='downlineDetails' exact path="/account/downline/details"
                              component={Layout(DownlineDetail, 'Downline Details')}/>
                <PrivateRoute key='profile' exact path="/main/profile" component={Layout(Profile, 'Downline Details')}/>
                {memoizedArray?.map(menu => (
                    <PrivateRoute key={menu?.key} exact path={menu?.path}
                                  component={Layout(menu?.MenuLayout, menu?.MenuName)}/>
                ))}
                <Route component={NoMatch} key="NoMatch"/>
            </Switch>
        </Router>
    );
}

export default AppRouter;
