import {
    SELECTED_LIST_ITEM,
    TOGGLE_LEFT_SIDE_BAR
} from "../actions/type";

const initialState = {
    lefSideBarControl: true,
    menuItemSelected: 1,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_LEFT_SIDE_BAR:
            return {
                ...state,
                lefSideBarControl: payload
            }
        case SELECTED_LIST_ITEM:
            return {
                ...state,
                menuItemSelected: payload
            }
        default:
            return state;
    }
}
